import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FaChartBar, FaDatabase, FaRobot, FaCloud, FaCode, FaTools,
  FaCheckCircle, FaUsers, FaGlobe, FaCogs
} from "react-icons/fa";
import {
  Container, Typography, Box, Button, Grid, Card,
  CardContent, IconButton, useTheme, Paper, Chip
} from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import "./Home.css";
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import TypewriterText from './components/TypewriterText';
import theme from './theme';  // Import from src/theme.js

const Home = () => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  const svgRef = useRef(null);

  const downloadLogo = (format) => {
    const svg = svgRef.current;
    const serializer = new XMLSerializer();
    const svgStr = serializer.serializeToString(svg);
    
    if (format === 'svg') {
      // Download as SVG
      const blob = new Blob([svgStr], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'vl-logo.svg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else if (format === 'png') {
      // Convert to PNG
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      
      // Set canvas size (doubled for better quality)
      canvas.width = 120;
      canvas.height = 120;
      ctx.scale(2, 2);
      
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
        const pngUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngUrl;
        link.download = 'vl-logo.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
      
      img.src = 'data:image/svg+xml;base64,' + btoa(svgStr);
    }
  };

  const stats = [
    {
      value: 10,
      label: "Projects Delivered",
      icon: <FaCheckCircle />,
      prefix: ""
    },
    {
      value: 3,
      label: "Industries Served",
      icon: <FaGlobe />,
      prefix: ""
    },
    {
      value: 1,
      label: "Consultants",
      icon: <FaUsers />,
      prefix: ""
    }
  ];

  const services = [
    {
      icon: <FaChartBar />,
      title: "Data Analytics & BI",
      description: "Transform raw data into actionable business intelligence with our advanced analytics solutions."
    },
    {
      icon: <FaDatabase />,
      title: "Enterprise Architecture",
      description: "Design and implement scalable infrastructure solutions for your organization."
    },
    {
      icon: <FaRobot />,
      title: "AI & Machine Learning",
      description: "Leverage cutting-edge AI technologies to automate and optimize your business processes."
    },
    {
      icon: <FaCloud />,
      title: "Cloud Transformation",
      description: "Seamlessly migrate and modernize your infrastructure with cloud-native solutions."
    },
    {
      icon: <FaCode />,
      title: "Custom Development",
      description: "Build tailored software solutions that align perfectly with your business objectives."
    },
    {
      icon: <FaTools />,
      title: "Digital Strategy",
      description: "Strategic IT consulting to drive your digital transformation initiatives."
    }
  ];

  const theme = useTheme();

  const serviceSlides = [
    {
      label: 'Web & Software Development',
      imgPath: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?auto=format&fit=crop&w=1200&h=600',
      description: 'Build modern web applications and custom software solutions using the latest technologies.',
      technologies: ['React', 'Node.js', 'Python', 'Java', 'Mobile Dev']
    },
    {
      label: 'Data Analytics',
      imgPath: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1200&h=600',
      description: 'Transform your data into actionable insights with modern analytics tools.',
      technologies: ['Power BI', 'Tableau', 'Python', 'R', 'SQL']
    },
    {
      label: 'Data Warehouse Management',
      imgPath: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&w=1200&h=600',
      description: 'Efficient data pipelines and high-performance storage solutions for your enterprise.',
      technologies: ['SQL', 'NoSQL', 'Oracle', 'MySQL', 'MongoDB', 'PostgreSQL']
    },
    {
      label: 'AI & Automation',
      imgPath: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&w=1200&h=600',
      description: 'Combine ready-to-use AI (ChatGPT, Azure Cognitive Services) with workflow automation (Power Automate, Zapier).',
      technologies: ['ChatGPT', 'Azure Cognitive Services', 'Zapier', 'Dialogflow']
    },
    {
      label: 'Cloud Services',
      imgPath: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1200&h=600',
      description: 'Scalable and flexible cloud infrastructure solutions.',
      technologies: ['AWS', 'Azure', 'Google Cloud', 'Docker', 'Kubernetes', 'Terraform']
    },
    {
      label: 'SEO & Marketing Optimization',
      imgPath: 'https://images.unsplash.com/photo-1517292987719-0369a794ec0f?auto=format&fit=crop&w=1200&h=600',
      description: 'Boost online visibility and reach with targeted SEO strategies and automated marketing campaigns.',
      technologies: ['SEO Tools', 'Google Analytics', 'HubSpot', 'Social Media Ads']
    },
    {
      label: 'IT Consulting',
      imgPath: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&w=1200&h=600',
      description: 'Expert technology guidance to drive your business forward.',
      technologies: ['IT Strategy', 'Digital Transformation', 'Agile', 'DevOps', 'ITIL']
    }
  ];

  const featuredServices = [
    {
      icon: <FaCode />,
      title: "Web & Software Development",
      description: "Build modern web applications and custom software solutions using the latest technologies.",
      link: "/services"
    },
    {
      icon: <FaChartBar />,
      title: "Data Analytics",
      description: "Build powerful dashboards and reports using Power BI, Tableau, and modern data solutions.",
      link: "/services"
    },
    {
      icon: <FaDatabase />,
      title: "Data Warehouse Management",
      description: "Optimize your enterprise data pipeline with secure, scalable warehouse solutions.",
      link: "/services"
    },
    {
      icon: <FaRobot />,
      title: "AI & Automation",
      description: "Combine AI tools like ChatGPT and Azure Cognitive Services with workflow automation (e.g., Power Automate, Zapier) to streamline operations and intelligence.",
      link: "/services"
    },
    {
      icon: <FaCloud />,
      title: "Cloud Services",
      description: "Scale your infrastructure seamlessly with modern cloud solutions and DevOps.",
      link: "/services"
    },
    {
      icon: <FaTools />,
      title: "IT Consulting",
      description: "Strategic technology guidance to drive your digital transformation initiatives.",
      link: "/services"
    },
    {
      icon: <FaCogs />,
      title: "SEO & Marketing Optimization",
      description: "Boost online visibility and reach with targeted SEO strategies and automated marketing campaigns.",
      link: "/services"
    }
  ];

  const fadeInUp = {
    initial: { y: 60, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  const staggerContainer = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const navigate = useNavigate();

  return (
    <Box>
      {/* Hero Section */}
      <Box
        sx={{
          backgroundColor: 'background.paper',  // Pure white background
          color: 'text.primary',
          pt: { xs: 8, md: 12 },
          pb: { xs: 8, md: 12 },
          position: 'relative',
          overflow: 'hidden'
          // Removed all background patterns and gradients
        }}
      >
        <Container maxWidth="lg" sx={{ position: 'relative' }}>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <motion.div 
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Box sx={{ textAlign: 'left' }}>
                  {/* <Box
                    sx={{
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <svg
                      ref={svgRef}
                      width="60"
                      height="60"
                      viewBox="0 0 100 100"
                      style={{
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.15))'
                      }}
                    >
                      
                      <circle
                        cx="50"
                        cy="50"
                        r="45"
                        fill="none"
                        stroke="url(#circleGradient)"
                        strokeWidth="3"
                      />
                     
                      <text
                        x="50"
                        y="55"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="url(#textGradient)"
                        style={{
                          fontSize: '57px',
                          fontFamily: '"JetBrains Mono", monospace',
                          fontWeight: 500,
                          letterSpacing: '-3px'
                        }}
                      >
                        VL
                      </text>
                    
                      <defs>
                        <linearGradient id="circleGradient" x1="100%" y1="0%" x2="0%" y2="100%">
                          <stop offset="15%" stopColor="#7B96FF" />
                          <stop offset="30%" stopColor="#2F5FFF" />
                          <stop offset="75%" stopColor="#051C2C" />
                          <stop offset="95%" stopColor="#03111B" />
                        </linearGradient>
                        <linearGradient id="textGradient" x1="100%" y1="0%" x2="0%" y2="100%">
                          <stop offset="15%" stopColor="#7B96FF" />
                          <stop offset="30%" stopColor="#2F5FFF" />
                          <stop offset="75%" stopColor="#051C2C" />
                          <stop offset="95%" stopColor="#03111B" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                    <Button
                      size="small"
                      onClick={() => downloadLogo('svg')}
                      sx={{
                        fontSize: '0.75rem',
                        background: theme.gradients.iconGradient,
                        color: 'white',
                        '&:hover': {
                          background: theme.gradients.iconGradientReversed,
                        }
                      }}
                    >
                      Download SVG
                    </Button>
                    <Button
                      size="small"
                      onClick={() => downloadLogo('png')}
                      sx={{
                        fontSize: '0.75rem',
                        background: theme.gradients.iconGradient,
                        color: 'white',
                        '&:hover': {
                          background: theme.gradients.iconGradientReversed,
                        }
                      }}
                    >
                      Download PNG
                    </Button>
                  </Box> */}
                  <TypewriterText 
                    variant="h1" 
                    sx={{ 
                      fontSize: { xs: '2.5rem', md: '3.5rem' },
                      mb: 2,
                      fontWeight: 700,
                      background: 'linear-gradient(45deg, #03111B 15%, #051C2C 30%, #2F5FFF 75%, #7B96FF 95%)',  // Multi-stop gradient for smoother transition
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      lineHeight: 1.2,
                      textAlign: 'left'
                    }}
                    text="VLImplement"
                    speed={50}
                  />
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      mb: 4,
                      color: 'text.secondary',
                      fontSize: '1.2rem',
                      maxWidth: 500,
                      lineHeight: 1.6,
                      textAlign: 'left'
                    }}
                  >
                    Transforming businesses through innovative technology solutions and implementation.
                  </Typography>
                  <Button
                    component={Link}
                    to="/contact"
                    variant="contained"
                    size="large"
                    sx={{
                      background: 'linear-gradient(45deg, #03111B 15%, #051C2C 30%, #2F5FFF 75%, #7B96FF 95%)',  // Matching title gradient
                      border: 'none',
                      color: 'white',
                      px: 4,
                      py: 1.5,
                      '&:hover': {
                        background: 'linear-gradient(45deg, #7B96FF 15%, #2F5FFF 30%, #051C2C 75%, #03111B 95%)',  // Reversed gradient
                        border: 'none',
                        opacity: 0.9
                      }
                    }}
                  >
                    Get in Touch
                  </Button>
                </Box>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={6}>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: -20,
                      right: -20,
                      width: 200,
                      height: 200,
                      background: 'radial-gradient(circle, rgba(9, 132, 227, 0.1) 0%, rgba(9, 132, 227, 0) 70%)',
                      borderRadius: '50%',
                      animation: 'pulse 4s infinite'
                    }
                  }}
                >
                  {/* Add tech-focused illustration or animation here */}
                </Box>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Stats Section */}
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <motion.div variants={staggerContainer}>
          <Grid container spacing={4} sx={{ mb: 0 }}>
            {stats.map((stat, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Box
                    sx={{
                      textAlign: 'center',
                      p: 3,
                      borderRadius: 2,
                      border: '1px solid',
                      borderColor: 'divider',
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontSize: '1.5rem',
                          color: 'white',
                          background: theme.gradients.iconGradient,
                          width: 48,
                          height: 48,
                          borderRadius: '50%',
                          mr: 2
                        }}
                      >
                        {stat.icon}
                      </Box>
                      <Typography
                        variant="h3"
                        sx={{
                          fontWeight: 700,
                          background: theme.gradients.primary,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        <CountUp
                          start={0}
                          end={stat.value}
                          duration={2.5}
                          separator=","
                          prefix={stat.prefix}
                          suffix=""
                        />
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ fontWeight: 500 }}
                    >
                      {stat.label}
                    </Typography>
                  </Box>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>

      {/* Services Section Heading (matching Services page style) */}
      <Container maxWidth="lg" sx={{ mt: 6 }}>
        <Typography
          variant="h2"
          sx={{
            mt: 10,
            mb: 0,
            fontWeight: 700,
            background: theme.gradients.primary,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
          align="center"
        >
          Our Services
        </Typography>

        {/* Slide deck or featuredServices listing goes here */}
        {/* For example: */}
        {/* <Box>
           <YourSlideDeckComponent slides={serviceSlides} />
        </Box> */}
      </Container>

      {/* Services Slideshow Section */}
      <Box sx={{ py: 8, backgroundColor: 'background.default' }}>
        <Container maxWidth="lg">

          <Swiper
            modules={[Autoplay, Pagination, Navigation, EffectFade]}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            loopedSlides={serviceSlides.length}
            watchSlidesProgress={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            effect="fade"
            className="services-swiper"
          >
            {serviceSlides.map((slide, index) => (
              <SwiperSlide 
                key={index}
                onClick={() => navigate(`/services?highlight=${slide.label}`)}
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    height: { xs: 400, md: 500 },
                    overflow: 'hidden',
                    borderRadius: 4,
                    cursor: 'pointer',
                    transition: 'transform 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.01)'
                    }
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: '100%',
                      display: 'block',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                    src={slide.imgPath}
                    alt={slide.label}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      background: 'linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.7) 60%, rgba(0,0,0,0.4) 100%)',
                      color: 'white',
                      p: 4,
                    }}
                  >
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        mb: 2,
                        fontWeight: 400,
                        fontSize: { xs: '1.75rem', md: '2.25rem' },
                        textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
                        letterSpacing: '0.5px',
                        color: 'rgba(255, 255, 255, 0.95)',
                        textAlign: 'left'
                      }}
                    >
                      {slide.label}
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mb: 2,
                        fontSize: { xs: '1rem', md: '1.1rem' },
                        fontWeight: 300,
                        opacity: 0.95,
                        maxWidth: '800px',
                        textAlign: 'left',
                        ml: 0,
                        color: 'rgba(255, 255, 255, 0.9)'
                      }}
                    >
                      {slide.description}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                      {slide.technologies.map((tech, i) => (
                        <Chip
                          key={i}
                          label={tech}
                          size="small"
                          sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.1)',
                            color: 'white',
                            '&:hover': {
                              bgcolor: 'rgba(255, 255, 255, 0.2)',
                            }
                          }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>

      {/* CTA Section: "Ready to get started?" */}
      <Box sx={{ py: 6 }}>
        <Container maxWidth="md" sx={{ textAlign: 'center', mb: 8 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              mb: 2,
            }}
          >
            Ready to get started?
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
            Schedule a consultation and let us help propel your business forward.
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Contact Us
          </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
